import React from 'react'
import { Head } from '~/components'
import MyEnvironment from '~/components/MyEnvironment'
import Newsletter from '~/components/Newsletter'

const Environment = () => (
  <>
    <Head />
    <MyEnvironment />
    <Newsletter />
  </>
)

export default Environment
