import React from 'react'
import cn from 'classnames'
import GoBackButton from './GoBackButton'
import * as st from '~/assets/styl/MyEnvironment.module.styl'

export default function MyEnvironment() {
  return (
    <div>
      <div className={cn(st.environmentContainer, 'page-width')}>
        <GoBackButton />
        <section className={st.environmentWrapper}>
          <h1>Meu Ambiente</h1>
          <div>
            <iframe
              src="https://www.roomvo.com/my/pointercombr/?visitor_id=c6e1495c7111491a9193aa61534c9472&tracking_code=&locale=pt-br&prefilter=&iframe_id=ffPopup&vendor_code=&product_type=0&domain=www.pointer.com.br&is_in_top_window=0&use_history_padding=0"
              frameBorder="0"
            ></iframe>
          </div>
        </section>
      </div>
    </div>
  )
}
